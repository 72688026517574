.wf-dialog[data-v-e607161a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -40%);
          transform: translate(-50%, -40%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}
.wf-dialog .el-dialog__body[data-v-e607161a] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow: auto;
}
[data-v-e607161a] .el-table .cell {
  line-height: 32px !important;
}
[data-v-e607161a] .el-button--primary {
  color: #FFF;
  background-color: #409EFF;
  border-color: #409EFF;
}
[data-v-e607161a] .el-button:hover {
  color: #409EFF;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}
[data-v-e607161a] .el-input__inner:focus {
  border-color: #409EFF;
}
[data-v-e607161a] .el-checkbox__inner:hover {
  border-color: #409EFF;
}
[data-v-e607161a] .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner[data-v-e607161a] {
  background-color: #409EFF;
  border-color: #409EFF;
}
[data-v-e607161a] .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #409EFF;
  border-color: #409EFF;
}
[data-v-e607161a] .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #409EFF;
}
[data-v-e607161a] .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #409EFF;
}
[data-v-e607161a] .el-pagination.is-background .el-pager li:not(.disabled).active {
  color: #FFFFFF;
  background-color: #409EFF;
}
[data-v-e607161a] .el-loading-spinner .path {
  stroke: #409EFF;
}
[data-v-e607161a] .el-select .el-input.is-focus .el-input__inner {
  border-color: #409EFF;
}
[data-v-e607161a] .el-pagination__sizes .el-input .el-input__inner:hover {
  border-color: #409EFF;
}